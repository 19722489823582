import { rfqGuestCountFormatSelector } from '@redux/experiments/selectors/rfq-guest-count-format';
import { useAppSelector } from '@redux/hooks';
import { compose } from '@xo-union/react-css-modules';
import React, { type FC } from 'react';
import type { useInlineRfqForm } from '../../hooks/useInlineRfqForm/useInlineRfqForm';
import Styles from './FormBody.scss';
import DateFlexible from './components/DateFlexible/DateFlexible';
import DatePickerField from './components/DatePickerField/DatePickerField';
import GuestCountSegmentedControl from './components/GuestCountSegmentedControl/GuestCountSegmentedControl';
import GuestCountSelect from './components/GuestCountSelect/GuestCountSelect';
import GuestCountSlider from './components/GuestCountSlider/GuestCountSlider';
import InputField from './components/InputField/InputField';
import MaskPhoneField from './components/MaskPhoneField/MaskPhoneField';
import TextareaField from './components/TextareaField/TextareaField';

export interface FormBodyProps {
	context: ReturnType<typeof useInlineRfqForm>;
}

export const FormBody: FC<FormBodyProps> = ({ context }) => {
	const rfqGuestCountFormatAssignment = useAppSelector(
		rfqGuestCountFormatSelector,
	);

	const containerClassNames = {
		container: Styles.container,
		'sub-text': Styles.subtext,
	};
	const inputClassNames = {
		...containerClassNames,
		input: 'fs-mask',
	};
	const datePickerClassNames = {
		container: Styles.datepickerContainer,
	};

	return (
		<form>
			<div className={Styles.namesContainer}>
				<InputField
					context={context}
					classes={compose(inputClassNames)}
					name="firstName"
					autoComplete="given-name"
					isRequired
				/>
				<InputField
					context={context}
					classes={compose(inputClassNames)}
					name="lastName"
					autoComplete="family-name"
					isRequired
				/>
			</div>
			<InputField
				context={context}
				classes={compose(inputClassNames)}
				name="emailAddress"
				autoComplete="email"
				isRequired
			/>
			<DatePickerField
				context={context}
				datePickerStyle={compose(datePickerClassNames)}
				isRequired
			/>
			<DateFlexible context={context} />
			{rfqGuestCountFormatAssignment?.startsWith('segmented_control') ? (
				<GuestCountSegmentedControl context={context} isRequired />
			) : rfqGuestCountFormatAssignment?.startsWith('slider') ? (
				<GuestCountSlider
					context={context}
					selectStyle={compose(containerClassNames)}
					isRequired
				/>
			) : (
				<GuestCountSelect
					context={context}
					selectStyle={compose(containerClassNames)}
					isRequired
				/>
			)}
			<MaskPhoneField context={context} classes={compose(inputClassNames)} />
			<TextareaField context={context} isRequired />
		</form>
	);
};

export default FormBody;
