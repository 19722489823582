import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';
import React from 'react';
import Styles from './QuickResponderBadge.scss';

interface QuickResponderBadgeProps {
	small?: boolean;
	shouldBold24h?: boolean;
	isDirectory?: boolean;
}

const QuickResponderBadge = ({
	small,
	shouldBold24h = true,
	isDirectory = false,
}: QuickResponderBadgeProps) => {
	const calculateResponseText = () => {
		if (isDirectory) {
			return 'Responds quickly';
		}

		if (small) {
			return (
				<>
					Typically responds within{' '}
					{shouldBold24h ? <strong>24h</strong> : '24h'}
				</>
			);
		}

		return 'Typically responds within 24 hours';
	};
	const responseText = calculateResponseText();

	return (
		<div className={Styles.quickResponderBadge}>
			<Icon name={small ? 'timeline' : 'quick_reply'} size="sm" />
			<Body2>{responseText}</Body2>
		</div>
	);
};

export default QuickResponderBadge;
