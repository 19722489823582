import { type RefObject, useEffect, useState } from 'react';

interface ElementDimensions {
	width: number;
	height: number;
	blockSize: number;
}

function useElementDimensionsObserver(
	ref: RefObject<HTMLElement>,
): ElementDimensions {
	const [dimensions, setDimensions] = useState<ElementDimensions>({
		width: 0,
		height: 0,
		blockSize: 0,
	});

	useEffect(() => {
		if (!ref.current) return;

		const observer = new ResizeObserver(([entry]) => {
			setDimensions({
				width: entry.contentRect.width,
				height: entry.contentRect.height,
				blockSize: entry.borderBoxSize[0].blockSize,
			});
		});

		observer.observe(ref.current);

		return () => {
			observer.disconnect();
		};
	}, [ref.current]);

	return dimensions;
}

export default useElementDimensionsObserver;
