import NavigableModal from '@components/NavigableModal/NavigableModal';
import { compose } from '@xo-union/react-css-modules';
import type { ModalClasses } from '@xo-union/tk-component-modals';
import React from 'react';
import type { FCWithChildren } from 'types/react-extended';
import type { UseInlineRfqFormReturn } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { checkIsTablet } from '../../../../utils';
import Styles from './RfqModalWrapper.scss';

const tabletModalClasses = {
	'button-container': Styles.tabletButtonContainer,
	'close-button': Styles.hide,
	large: Styles.tabletModal,
};

const mobileModalClasses = {
	container: Styles.container,
	content: Styles.content,
};

export interface ModalWrapperProps {
	context: UseInlineRfqFormReturn;
	viewport: Redux.Viewport;
	onClose: () => void;
	isModalOnModal?: boolean;
}

export const RfqModalWrapper: FCWithChildren<ModalWrapperProps> = ({
	context,
	children,
	viewport,
	onClose,
	isModalOnModal = false,
}) => {
	const isTabletOrMedium = checkIsTablet(viewport);
	const { isMobile } = viewport;
	const isDesktop = !isTabletOrMedium && !isMobile;

	let composedClasses: Partial<Record<ModalClasses, string>> = {};

	if (isMobile) {
		composedClasses = mobileModalClasses;
	} else if (isTabletOrMedium) {
		composedClasses = tabletModalClasses;
	} else {
		composedClasses = { small: Styles.containerDesktop };
	}

	composedClasses['button-container'] = Styles.show;

	return (
		<NavigableModal
			hash="rfq-modal"
			isModalOpen={context.isModalOpen}
			closeCallback={onClose}
			classes={compose(composedClasses)}
			size={isDesktop ? 'sm' : 'lg'}
			isModalOnModal={isModalOnModal}
		>
			{children}
		</NavigableModal>
	);
};
