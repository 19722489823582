import type { ClassesMapper } from '@xo-union/react-css-modules';
import { Body1, Caption } from '@xo-union/tk-ui-typography';
import React, { useCallback, useEffect, useState, type FC } from 'react';
import { RangeSlider } from '../../../../../../../VendorsSearch/containers/FilterPills/components/StartingPriceFilter/range-slider';
import { unifyGuestNumberFormat } from '../../../../data/loader/utils/general';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { handleFieldChange } from '../../../../utils';
import Styles from './GuestCountSlider.scss';

export interface GuestCountSliderProps {
	selectStyle: ClassesMapper<string>;
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
}

export const GuestCountSlider: FC<GuestCountSliderProps> = (props) => {
	const { context } = props;
	const { setFieldValue, shouldShowErrors } = context;
	const { guestCount: contextValue } = context.values;
	const SLIDER_STEP = 50;

	const [minVal, setMinVal] = useState(0);
	const [maxVal, setMaxVal] = useState(350);
	const [initialValues, setInitialValues] = useState('');
	const [selectedValue, setSelectedValue] = useState('');
	const startingMin = 0;
	const startingMax = 350;

	const handleGuestCountChange = (value: string) => {
		const newValue = value.includes('350') ? '301 or more' : value;

		handleFieldChange({
			cb: setFieldValue,
			data: contextValue,
			name: 'guestCount',
			value: newValue,
		});
	};

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const setMinSliderValue = useCallback(
		(value: number) => {
			const shouldSetToStarting =
				Number.isNaN(value) || value <= startingMin || value >= maxVal;
			setMinVal(shouldSetToStarting ? startingMin : value);
			setSelectedValue(`${value}–${maxVal}`);
		},
		[startingMin, maxVal, setMinVal],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
	const setMaxSliderValue = useCallback(
		(value: number) => {
			const shouldSetToStarting =
				Number.isNaN(value) || value >= startingMax || value <= minVal;
			setMaxVal(shouldSetToStarting ? startingMax : value);
			setSelectedValue(`${minVal}–${value}`);
		},
		[startingMax, minVal, setMaxVal],
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: handleGuestCountChange is a callback that should not be used as a dependency
	useEffect(() => {
		handleGuestCountChange(selectedValue);
	}, [selectedValue]);

	useEffect(() => {
		if (!initialValues && contextValue.value) {
			setInitialValues(contextValue.value);
		}
	}, [contextValue.value, initialValues]);

	useEffect(() => {
		if (initialValues) {
			const currentValue = unifyGuestNumberFormat(initialValues);

			const [min, max] =
				currentValue === '301 or more' ? [0, 350] : currentValue.split('–');

			setMinVal(Number.parseInt(min.toString()));
			setMaxVal(Number.parseInt(max.toString()));
			setSelectedValue(`${min}–${max}`);
		}
	}, [initialValues]);

	const setMin = useCallback(
		(value: number) => {
			setMinSliderValue(value);
		},
		[setMinSliderValue],
	);

	const setMax = useCallback(
		(value: number) => {
			setMaxSliderValue(value);
		},
		[setMaxSliderValue],
	);

	return (
		<div className={Styles.rangeSliderContent}>
			<Body1>Number of guests*</Body1>

			{shouldShowErrors && contextValue.error && (
				<Caption size="sm" className={Styles.hasError}>
					{contextValue.error}
				</Caption>
			)}

			<RangeSlider
				startingMin={startingMin}
				startingMax={startingMax}
				minVal={minVal}
				maxVal={maxVal}
				setMinVal={setMin}
				setMaxVal={setMax}
				step={SLIDER_STEP}
				shouldShowErrors={shouldShowErrors && Boolean(contextValue.error)}
				shouldShowLabels={true}
				shouldShowSteps={true}
			/>
			<input type="hidden" name="guestCount" value={selectedValue} />
		</div>
	);
};

export default GuestCountSlider;
