import { compose } from '@xo-union/react-css-modules';
import { type TextAreaClasses, Textarea } from '@xo-union/tk-component-fields';
import classNames from 'classnames';
import React, { type FC, useState, type ChangeEvent, useMemo } from 'react';
import { connect } from 'react-redux';
import type { useInlineRfqForm } from '../../../../hooks/useInlineRfqForm/useInlineRfqForm';
import { useTrackInlineForm } from '../../../../hooks/useTrackInlineForm/use-track-inline-form';
import { handleFieldChange } from '../../../../utils';
import { getFieldLabel } from '../helpers';
import Styles from './TextareaField.scss';

const { textareaContainer, textareaInput, subtext, labelOverflow } = Styles;

export interface OwnProps {
	context: ReturnType<typeof useInlineRfqForm>;
	isRequired?: boolean;
	onChange?: (value: string) => void;
	onBlur?: () => void;
	shouldShowLabel?: boolean;
}
type StateProps = ReturnType<typeof mapStateToProps>;
export type TextareaFieldProps = OwnProps & StateProps;

export const TextareaField: FC<TextareaFieldProps> = (props) => {
	const {
		context: {
			setFieldValue,
			shouldShowErrors,
			values,
			freeTextLabel,
			initiator,
		},
		isRequired = false,
		onBlur,
		onChange,
		shouldShowLabel = true,
	} = props;

	const { textarea: contextValue } = values;
	const [value, setValue] = useState<string>(contextValue.value);
	const textareaClass = compose({
		container: classNames(textareaContainer, labelOverflow),
		// Do we have an example of compose that adds two classes to a property?
		input: `${textareaInput} fs-mask`,
		'sub-text': subtext,
	} as Partial<TextAreaClasses>);

	const trackTextareaClick = useTrackInlineForm(
		'Click on text area',
		initiator,
	);

	// biome-ignore lint/correctness/useExhaustiveDependencies: input cannot be changed if we add "value" as dependency
	useMemo(() => {
		if (contextValue.value && contextValue.value !== value) {
			setValue(contextValue.value);
		}
	}, [contextValue.value]);

	const handleOnChange = (event: ChangeEvent<HTMLTextAreaElement>) => {
		setValue(event.target.value);
		onChange?.(event.target.value);
	};

	return (
		<Textarea
			classes={textareaClass}
			name="textarea"
			label={
				shouldShowLabel &&
				getFieldLabel(freeTextLabel || contextValue.label, isRequired)
			}
			onChange={handleOnChange}
			value={value}
			onBlur={() => {
				trackTextareaClick();
				console.info('handleFieldChange onBlur');
				handleFieldChange({
					cb: setFieldValue,
					data: contextValue,
					name: 'textarea',
					value,
				});
				onBlur?.();
			}}
			state={shouldShowErrors ? contextValue.state : 'neutral'}
			subText={shouldShowErrors ? contextValue.error : ''}
		/>
	);
};

const mapStateToProps = (state: Redux.State) => ({
	textarea: state.rfq.inline.fields.textarea,
});

export default connect(mapStateToProps)(TextareaField);
