import React from 'react';
import Styles from './CtaBadge.scss';

import type { IconName } from '@xo-union/dist-iconography';
import Icon from '@xo-union/tk-component-icons';
import { Body2 } from '@xo-union/tk-ui-typography';

interface CtaBadgeProps {
	badgeIcon: IconName;
	badgeText: string;
}

export const CtaBadge: React.FC<CtaBadgeProps> = ({ badgeIcon, badgeText }) => {
	return (
		<div className={Styles.ctaBadge}>
			{badgeIcon && <Icon name={badgeIcon} size="sm" />}
			<Body2 bold>{badgeText}</Body2>
		</div>
	);
};
